/**
 * @module ViewApp
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  Datagrid,
  DeleteButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'

function TrendingFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="language_tag" alwaysOn={true} initialValue="en" />
    </Filter>
  )
}

/**
 * Trending list react component.
 *
 * @param {object} props - Properties to customize the list.
 * @returns {React.ReactElement} - The component.
 *
 */
export function TrendingList(props) {
  return (
    <List {...props} filters={<TrendingFilter />}>
      <Datagrid>
        <TextField source="text" sortable={false} />
        <TextField source="id" sortable={false} />
        <TextField source="language_tag" sortable={false} />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}
