/**
 * @module ViewApp
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin'

function SuggestionFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="query" alwaysOn={true} />
      <TextInput source="language_tag" alwaysOn={true} initialValue="en" />
    </Filter>
  )
}

/**
 * Suggestion list react component.
 *
 * @param {object} props - Properties to customize the list.
 * @returns {React.ReactElement} - The component.
 *
 */
export function SuggestionList(props) {
  return (
    <List {...props} filters={<SuggestionFilter />}>
      <Datagrid>
        <TextField source="query" sortable={false} />
        <TextField source="frequency" sortable={false} />
        <TextField source="id" sortable={false} />
        <TextField source="language_tag" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

/**
 * Suggestion layout react component.
 *
 * @param {object} props - Properties to customize the layout.
 * @returns {React.ReactElement} - The component.
 *
 */
export function SuggestionShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="query" />
        <TextField source="frequency" />
        <TextField source="id" />
        <TextField source="language_tag" />
      </SimpleShowLayout>
    </Show>
  )
}

/**
 * Suggestion editor react component.
 *
 * @param {object} props - Properties to customize the editor.
 * @returns {React.ReactElement} - The component.
 *
 */
export function SuggestionEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled={true} source="id" />
        <TextInput source="query" validate={[required()]} />
        <TextInput source="language_tag" validate={[required()]} />
        <TextInput source="frequency" validate={[required()]} />
      </SimpleForm>
    </Edit>
  )
}

/**
 * Suggestion creator react component.
 *
 * @param {object} props - Properties to customize the creator.
 * @returns {React.ReactElement} - The component.
 *
 */
export function SuggestionCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="query" validate={[required()]} />
        <TextInput source="language_tag" validate={[required()]} />
        <NumberInput source="frequency" validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}
